import type {ReactElement} from 'react';
import React, {useRef} from 'react';
import {isPAYGUser, isPremiumPlusUser, isPremiumUser, isUserDesigner, isUserReseller, isUserStudent, isUserTeacher} from '@Utils/user.util';
import {SidebarListItem} from '@Components/mystuff-left-sidebar/components/sidebar-list-item';
import {
  isContentPlannerPage,
  isDesignerActionCenterEnabled,
  isPageHashIncludedInList,
  isPageRelatedToMyDesigns,
  isPageRelatedToMyEmails,
  isPageRelatedToSocialMediaPublishing,
} from '@Libraries/my-stuff/my-stuff-library';
import {isBusinessProfilesEnabled, isContentPlannerEnabled, MY_STUFF_HASHES} from '@Libraries/my-stuff-library';
import {openUpsellingDialogForContentPlanner} from '@Libraries/content-planner-library';
import type {SidebarListItemContent} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.types';
import {SIDEBAR_LIST_TYPE} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.types';
import {openUpsellingDialogForBrands} from '@Libraries/brands-library';
import type {SidebarListProps} from '@Components/mystuff-left-sidebar/components/sidebar-list';
import {MobileSidebarItem} from '@Components/mystuff-left-sidebar/components/mobile-sidebar-item/mobile-sidebar-item';
import {areThereTemplatesThatNeedAttention, getFormattedNeedsAttentionTemplatesNumber} from '@Libraries/my-stuff/designer-dashboard-library';
import {SocialMediaMystuffSidebarPopover} from '@Components/social-media/components/social-media-mystuff-sidebar-popover/social-media-mystuff-sidebar-popover';

export interface UseSidebarListItemsParams extends SidebarListProps {
  listItemClassName?: string;
  isMobileItem?: boolean;
  excludedPageHashes?: string[];
}

export interface UseSidebarListItemsResult {
  listItems: ReactElement<SidebarListItemContent>[];
  selectedItemIndex: number;
}

export const useSidebarListItems = (opts: UseSidebarListItemsParams): UseSidebarListItemsResult => {
  switch (opts.listType) {
    case SIDEBAR_LIST_TYPE.SECONDARY:
      return getSecondarySidebarList(opts);

    case SIDEBAR_LIST_TYPE.MISC:
      return getMiscellaneousItemsSidebarList(opts);

    case SIDEBAR_LIST_TYPE.PRIMARY:
    default:
      return getPrimarySidebarList(opts);
  }
};

const getIntroPopupForSocialMedia = (isMobile: boolean): ReactElement => {
  return <SocialMediaMystuffSidebarPopover isMobile={isMobile} />;
};

const getRefForSocialMedia = (): React.RefObject<HTMLLIElement> => {
  return useRef<HTMLLIElement>(null);
};

const getPrimarySidebarList = (sidebarOpts: UseSidebarListItemsParams): UseSidebarListItemsResult => {
  const itemsList: ReactElement<SidebarListItemContent>[] = [];
  const {userType, userPremiumLevel, pageHash} = sidebarOpts;
  const itemsSelectionState = [];

  if (isUserReseller(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.window.i18next.t('pmwjs_my_clients'),
        icon: 'icon-teamwork',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.myClients]),
        url: window.PMW.util.site_url('posters/mine#/my-clients'),
        className: sidebarOpts.listItemClassName,
      })
    );

    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.myClients]));
  }

  if (isUserTeacher(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.window.i18next.t('pmwjs_class_projects'),
        icon: 'icon-lightbulb',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.classProjects]),
        url: window.PMW.util.site_url('posters/mine#/class-projects'),
        className: sidebarOpts.listItemClassName,
      })
    );

    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.classProjects]));
  }

  itemsList.push(
    getSidebarItemForList(sidebarOpts, {
      text: window.window.i18next.t('pmwjs_designs'),
      icon: 'icon-designs',
      isSelected: isPageRelatedToMyDesigns(pageHash),
      url: window.PMW.util.site_url('posters/mine#/designs'),
      className: sidebarOpts.listItemClassName,
    })
  );

  itemsSelectionState.push(isPageRelatedToMyDesigns(pageHash));
  if (!isUserStudent(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.window.i18next.t('pmwjs_emails'),
        icon: 'icon-envelope',
        isSelected: isPageRelatedToMyEmails(pageHash),
        url: window.PMW.util.site_url('posters/mine#/email-marketing-campaigns'),
        className: sidebarOpts.listItemClassName,
        showPremiumIcon: !isPremiumUser(userPremiumLevel),
      })
    );

    const isMobile = sidebarOpts.isMobileItem === undefined ? false : sidebarOpts.isMobileItem;
    const refSocialMedia = getRefForSocialMedia();
    itemsList.push(
      getSidebarItemForList(
        sidebarOpts,
        {
          text: window.window.i18next.t('pmwjs_social_media_posts'),
          icon: 'icon-social-media',
          isSelected: isPageRelatedToSocialMediaPublishing(pageHash),
          url: window.PMW.util.site_url('posters/mine#/posts'),
          className: `${sidebarOpts.listItemClassName} -capitalizedtext`,
        },
        undefined,
        getIntroPopupForSocialMedia(isMobile),
        refSocialMedia
      )
    );

    itemsSelectionState.push(isPageRelatedToMyEmails(pageHash));
    itemsSelectionState.push(isPageRelatedToSocialMediaPublishing(pageHash));

    if (isContentPlannerEnabled()) {
      itemsList.push(
        getSidebarItemForList(sidebarOpts, {
          text: window.window.i18next.t('pmwjs_content_planner'),
          icon: 'icon-content-planner',
          isSelected: isContentPlannerPage(pageHash),
          url: window.PMW.util.site_url(`posters/mine${MY_STUFF_HASHES.contentPlanner}`),
          className: sidebarOpts.listItemClassName,
          onClick: openUpsellingDialogForContentPlanner,
          isNewPage: false,
          showPremiumIcon: !isPremiumPlusUser(userPremiumLevel),
          isLink: isPremiumPlusUser(userPremiumLevel),
        })
      );

      itemsSelectionState.push(isContentPlannerPage(pageHash));
    }
  }

  return {listItems: itemsList, selectedItemIndex: itemsSelectionState.indexOf(true)};
};

const getSecondarySidebarList = (sidebarOpts: UseSidebarListItemsParams): UseSidebarListItemsResult => {
  const itemsList = [];
  const {userType, userPremiumLevel, pageHash} = sidebarOpts;
  const isPremium = isPremiumUser(userPremiumLevel);
  const itemsSelectionState = [];

  if (isBusinessProfilesEnabled() && isPremium && !sidebarOpts.excludedPageHashes?.includes(MY_STUFF_HASHES.businessProfile)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_my_business'),
        icon: 'icon-business',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.businessProfile]),
        url: window.PMW.util.site_url('posters/mine#/business-profile'),
        className: sidebarOpts.listItemClassName,
        hasBorderAnimation: true,
      })
    );
    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.brands]));
  }

  if (isUserDesigner(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_dashboard'),
        icon: 'icon-filter',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.designerInsights, MY_STUFF_HASHES.designerActivity, MY_STUFF_HASHES.designerEarnings]),
        url: window.PMW.util.site_url('posters/mine#/insights'),
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.designerInsights, MY_STUFF_HASHES.designerActivity, MY_STUFF_HASHES.designerEarnings]));
  }

  if (!isUserStudent(userType) && !isUserReseller(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_team'),
        icon: 'icon-facebook-group',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.teamSpace]),
        url: window.PMW.util.site_url('posters/mine#/team-space'),
        showPremiumIcon: !isPremium,
        isLink: isPremium,
        onClick: openUpsellingDialogForTeams,
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.teamSpace]));
  }

  if (!isUserStudent(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_brand_kits'),
        icon: 'icon-brands',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.brands]),
        url: window.PMW.util.site_url('posters/mine#/brands'),
        showPremiumIcon: !isPremiumPlusUser(userPremiumLevel),
        isLink: isPremiumPlusUser(userPremiumLevel),
        onClick: openUpsellingDialogForBrandKits,
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.brands]));
  }

  if (isUserDesigner(userType) && isDesignerActionCenterEnabled()) {
    itemsList.push(
      getSidebarItemForList(
        sidebarOpts,
        {
          text: window.i18next.t('pmwjs_action_center'),
          icon: 'icon-exclamation-triangle',
          isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.improveTemplates, MY_STUFF_HASHES.rejectedTemplates]),
          url: window.PMW.util.site_url('posters/mine#/improve-templates'),
          className: sidebarOpts.listItemClassName,
        },
        areThereTemplatesThatNeedAttention() ? (
          <div className="radius-round content-body-white bg-danger-200 body-xxs-bold spacing-p-r-2 spacing-p-l-2 spacing-p-b-1 spacing-p-t-1">
            {getFormattedNeedsAttentionTemplatesNumber()}
          </div>
        ) : undefined
      )
    );
    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.improveTemplates, MY_STUFF_HASHES.rejectedTemplates]));
  }

  return {listItems: itemsList, selectedItemIndex: itemsSelectionState.indexOf(true)};
};

const getMiscellaneousItemsSidebarList = (sidebarOpts: UseSidebarListItemsParams): UseSidebarListItemsResult => {
  const itemsList = [];
  const {userType, userPremiumLevel, pageHash} = sidebarOpts;
  const itemsSelectionState = [];

  if (isUserDesigner(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_leaderboard'),
        icon: 'icon-trophy',
        isSelected: false,
        url: window.PMW.util.site_url('sell/leaderboard'),
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(false);
  }

  if (isUserTeacher(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_keep_classrooms_free'),
        icon: 'icon-happy-woman',
        isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.keepClassroomsFree]),
        url: window.PMW.util.site_url('posters/mine#/keep-free'),
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.keepClassroomsFree]));
  }

  if (!isPAYGUser(userPremiumLevel) && !isUserDesigner(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_premium_billing'),
        icon: 'icon-crown',
        isSelected: false,
        url: window.PMW.util.site_url('premium/subscriptions'),
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(false);
  } else if (!isUserDesigner(userType) && !isUserStudent(userType)) {
    itemsList.push(
      getSidebarItemForList(sidebarOpts, {
        text: window.i18next.t('pmwjs_order_history'),
        icon: 'icon-history',
        isSelected: false,
        url: window.PMW.util.site_url('cart/orderhistory'),
        className: sidebarOpts.listItemClassName,
      })
    );
    itemsSelectionState.push(false);
  }

  itemsList.push(
    getSidebarItemForList(sidebarOpts, {
      text: window.i18next.t('pmwjs_trash'),
      icon: 'icon-delete',
      isSelected: isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.trash, MY_STUFF_HASHES.trashSocialPosts, MY_STUFF_HASHES.trashEmailCampaigns]),
      url: window.PMW.util.site_url('posters/mine#/trash'),
      className: sidebarOpts.listItemClassName,
    })
  );

  itemsSelectionState.push(isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.trash, MY_STUFF_HASHES.trashSocialPosts, MY_STUFF_HASHES.trashEmailCampaigns]));

  return {listItems: itemsList, selectedItemIndex: itemsSelectionState.indexOf(true)};
};

const openUpsellingDialogForTeams = (): void => {
  window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_TEAMS);
};

const openUpsellingDialogForBrandKits = (): void => {
  openUpsellingDialogForBrands();
};

const getSidebarItemForList = (
  sidebarOpts: UseSidebarListItemsParams,
  itemParams: SidebarListItemContent,
  children?: ReactElement,
  itemPopover?: ReactElement,
  popoverRef?: React.RefObject<HTMLLIElement>
): ReactElement<SidebarListItemContent> => {
  const {text, ...commonParams} = itemParams;

  return sidebarOpts.isMobileItem ? (
    <MobileSidebarItem popoverRef={popoverRef} itemPopover={itemPopover} key={text} {...commonParams} />
  ) : (
    <SidebarListItem popoverRef={popoverRef} itemPopover={itemPopover} key={text} {...commonParams} text={text}>
      {children}
    </SidebarListItem>
  );
};
